<template>
  <div>
    <div class="table-responsive" v-if="tasks_types && onProgress === false">
      <table class="table table-condensed table-bordered">
        <thead>
        <tr>
          <th v-for="task_type in tasks_types"
              :style="'background:' + task_type.custom_color.replace('0.25', '0.5')">
            {{ task_type.name }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-for="tasks_type in tasks_types" v-bind:style="'width: ' + (column_width ? (column_width + '%') : 'auto')">
            <div v-for="task in tasks_type.tasks">
              <task-new-item :task="task"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <loading-processing v-else></loading-processing>

    <div class="panel panel-default" v-if="tasks_types.length === 0 && onProgress === false">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component('task-new-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'new-tasks',

  components: {},

  props: {
    data_search: {
      type: String,
      required: false
    },
  },

  data: () => ({
    tasks_types: [],

    column_width: null,

    onProgress: true,
  }),

  async created() {
    try {
      this.EventBus.$on('task-items-reload', this.getTasksTypes);

      await this.getTasksTypes();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    async getTasksTypes() {
      try {
        this.onProgress = true;

        const tasks_types_response_items = await API.apiClient.get('/tasks/new');

        this.tasks_types = tasks_types_response_items.data;

        this.column_width = 100 / this.tasks_types.length;
      } catch (error) {
        errorHandler(error);
      }
      this.onProgress = false;
    }
  },
}
</script>

<style scoped>
tr > td {
  padding: 10px;
}

tr:hover {

  background: unset;
}

td:hover {
  background: unset;
}
</style>

<style scoped>
th {
  border-bottom: 0 !important;
}

tr > td {
  padding: 0 !important;
}
</style>